import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { ChatsService } from '../../core/services/chats.service';
import { PaginationComponent } from '../../core/components/pagination/pagination.component';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-list-chats',
  templateUrl: './list-chats.component.html',
  styleUrls: ['./list-chats.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    PaginationComponent,
    TranslateModule
  ]
})
export class ListChatsComponent {
  loading = true;
  faSpinner = faSpinner;
  faTrash = faTrash;

  chats;
  currentPage = 1;
  deleteChat = undefined;
  modalCreateChat = false;

  chatName = '';

  constructor(
    private _chatsService: ChatsService,
    private _router: Router
  ) { }

  async ngOnInit() {
    localStorage.removeItem('selectedChat');
    
    this.loadData();
  }

  async loadData(reset = false) {
    if(reset) {
      this.currentPage = 1;
    }

    this.loading = true;

    this.chats = await this._chatsService.getListChats(this.currentPage);

    this.loading = false;
  }

  selectChat(chat) {
    localStorage.setItem('selectedChat', chat.id_column);

    this._router.navigate(['chat-detail']);
  }

  async confirmDeleteChat() {
    if(this.deleteChat) {
      await this._chatsService.deleteChat(this.deleteChat.id_column);
      this.deleteChat = undefined;

      this.loadData(true);
    }
  }

  async createChat() {
      await this._chatsService.newChat(this.chatName);
      this.chatName = '';
      this.modalCreateChat = false;

      this.loadData(true);
  }
}
