<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
        {{'HISTORIC.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column is-12 mb-4">
        <div class="columns" [formGroup]="historicForm">
          <div class="column is-6">
            <div class="mb-2">
              <label class="label">{{'HISTORIC.FILTER_DATE' | translate}}</label>
            </div>
            <p-calendar
              dateFormat="dd/mm/yy"
              formControlName="dateRange"
              selectionMode="range"/>
          </div>
          <div class="column is-6 is-flex is-justify-content-end search-button-container">
            <button class="button is-primary" (click)="loadData(true)">
              {{'HISTORIC.SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-4 is-flex is-justify-content-center mb-3 metric">
            <label>{{'HISTORIC.NUMBER_SESSIONS' | translate}}</label>
    
            <div class="info-box">
              <span>{{metrics.total_number}}</span>
            </div>
          </div>
          <div class="column is-4 is-flex is-justify-content-center mb-3 metric">
            <label>{{'HISTORIC.COST_TOTAL' | translate}}</label>
    
            <div class="info-box">
              <span>{{metrics.total_cost}} $</span>
            </div>
          </div>
          <div class="column is-4 is-flex is-justify-content-center mb-3 metric">
            <label>{{'HISTORIC.AVERAGE_COST_SESSION' | translate}}</label>
    
            <div class="info-box">
              <span>{{metrics.average_cost}} $</span>
            </div>
          </div>
          <div class="column is-6 is-flex is-justify-content-center mb-3 metric">
            <label>{{'HISTORIC.AVERAGE_DURATION' | translate}}</label>
    
            <div class="info-box">
              <span>{{metrics.average_time}}</span>
            </div>
          </div>
          <div class="column is-6 is-flex is-justify-content-center mb-3 metric">
            <label>{{'HISTORIC.MESSAGES_SESSION' | translate}}</label>
    
            <div class="info-box">
              <span>{{metrics.average_number_messages}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <p-accordion>
          @for (session of historic.data.sessions; let i = $index; track i) {
            <p-accordionTab [header]="getTitleSession(session)">
              <div>
                <div class="messages-box">
                  <div *ngFor="let message of session.messages">
                      <div *ngIf="message.interlocutor_column === 'user'" class="message-wrapper local-message">
                          <div class="message local-message">
                              <div>
                                <img class="user-photo" src="assets/icons/ico-user.png" alt="User Photo">
                              </div>
                              <div class="message-bubble message-local">
                                <p class="message-text">{{message.text_column}}</p>
                                <p class="message-date">{{message.date_column.split(' ')[1].split(':')[0] + ':' +
                                    message.date_column.split(' ')[1].split(':')[1]}}</p>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="message.interlocutor_column === 'bot'" class="message-wrapper">
                          <div class="message">
                              <div>
                                <img class="user-photo" [src]="chatConfig.image" alt="User Photo">
                              </div>
                              <div class="message-bubble message-xati">
                                <div class="message-container">
                                  <p [innerHTML]="message.text_column" class="message-text"></p>
                                  <p class="message-date">{{message.date_column.split(' ')[1].split(':')[0] + ':' +
                                      message.date_column.split(' ')[1].split(':')[1]}}</p>
                                </div>
                              </div>
                              <div *ngIf="message.feedback_column">
                                <fa-icon *ngIf="message.feedback_column === 1" [icon]="faThumbsUp" class="feedback-icon"></fa-icon>
                                <fa-icon *ngIf="message.feedback_column === -1" [icon]="faThumbsDown" class="feedback-icon"></fa-icon>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          }
        </p-accordion>
      </div>
      <div class="column is-12">
        <app-pagination
          [currentPage]="currentPage"
          [totalPages]="historic.metadata.total_pages"
          (pageChange)="currentPage = $event; loadData()"></app-pagination>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>