import { Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { ListChatsComponent } from './modules/list-chats/list-chats.component';
import { ChatDetailsComponent } from './modules/chat-detail/chat-detail.component';
import { hasListChats, isChatOpen, isLogged, needSelectedChat } from './core/guards/auth.guard';
import { CustomComponent } from './modules/custom/custom.component';
import { DemoChatComponent } from './modules/demo-chat/demo-chat.component';
import { StatusComponent } from './modules/status/status.component';
import { LogComponent } from './modules/log/log.component';
import { HistoricComponent } from './modules/historic/historic.component';
import { IndexingComponent } from './modules/index/index.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'list-chats',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'list-chats',
        component: ListChatsComponent,
        canActivate: [isLogged, hasListChats]
    },
    {
        path: 'chat-detail',
        component: ChatDetailsComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'historic',
        component: HistoricComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'index',
        component: IndexingComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'log',
        component: LogComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'status',
        component: StatusComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'demo-chat',
        component: DemoChatComponent,
        canActivate: [isLogged, needSelectedChat]
    },
    {
        path: 'chat/:id',
        component: DemoChatComponent,
        canActivate: [isChatOpen]
    },
    {
        path: 'custom',
        component: CustomComponent,
        canActivate: [isLogged, needSelectedChat]
    }
];
