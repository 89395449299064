<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
        {{'STATUS.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column is-12">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>
                {{'STATUS.NAME' | translate}}
              </th>
              <th>
                {{'STATUS.NUMBER_FILES' | translate}}
              </th>
              <th>
                {{'STATUS.EXISTS' | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
              @for (data of status.tables; let i = $index; track i) {
                <tr>
                  <td>{{ data.name }}</td>
                  <td>{{ data.number_rows }}</td>
                  <td>
                    <fa-icon [icon]="data.exist ? faCheck : faTimes" [ngClass]="{'has-text-success': data.exist, 'has-text-danger': !data.exist}"></fa-icon>
                  </td>
                </tr>
              } @empty {
                <tr>
                  <td colspan="3" class="has-text-centered">
                    {{'GENERIC.NO_DATA' | translate}}
                  </td>
                </tr>
              }
          </tbody>
      </table>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>