import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  version: string;

  constructor(
    private http: HttpClient
  ) { }

  async getVersion() {
    if(!this.version) {
      const version = await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/system/version`))
      this.version = version.version;
    }

    return this.version
  }
}
