import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private http: HttpClient
  ) { }

  async getLog(page: number, filters: any) {
    const body = {
      page,
      "per_page": 10,
      ...(filters.user_id ? { user_id: filters.user_id } : {}),
      ...(filters.area_id ? { area_id: filters.area_id.value } : {}),
      ...(filters.type_id ? { type_id: filters.type_id.value } : {}),
      ...(filters.dateRange ? { start_date: dayjs(filters.dateRange[0]).format('YYYY-MM-DDTHH:mm'), end_date: dayjs(filters.dateRange[1]).format('YYYY-MM-DDTHH:mm')} : {})
    };

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/actions`, body))
  }

  async getLogTypes() {
    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/action_types`, {}))
  }

  async getLogAreas() {
    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/action_areas`, {}))
  }
}
