<div class="container">
  @if(!isOpenChat) {
    <div class="columns">
      <div class="column">
        <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
          <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
          {{'DEMO_CHAT.TITLE' | translate}}
        </h4>
      </div>
    </div>
  }
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column" style="height: 90vh;">
        <chat-widget [isFullView]="true"></chat-widget>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>