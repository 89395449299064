import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  faNext = faChevronRight;
  faPrevious = faChevronLeft;

  @Input() currentPage: number;
  @Input() totalPages: number;

  @Output() pageChange = new EventEmitter<number>();

  constructor() { }

  setPage(page): void {
    if(page !== '...') {
      this.pageChange.emit(page);
    }
  }

  prevPage(): void {
    this.setPage(this.currentPage - 1);
  }

  nextPage(): void {
    this.setPage(this.currentPage + 1);
  }

  get totalPagesArray(): any[] {
    let pageNumbers = [];
    const visiblePages = 5;
    const maxVisiblePages = Math.min(visiblePages, this.totalPages);
  
    if (this.totalPages <= visiblePages) {
      pageNumbers = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else {
      const startPage = Math.max(1, this.currentPage - (Math.floor(visiblePages / 2) - 1));
      const endPage = Math.min(this.totalPages, startPage + maxVisiblePages - 1);
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
  
    if (pageNumbers[0] > 2) {
      pageNumbers.unshift(1, '...');
    } else if (pageNumbers[0] === 2) {
      pageNumbers.unshift(1);
    }
  
    if (pageNumbers[pageNumbers.length - 1] < this.totalPages - 1) {
      pageNumbers.push('...', this.totalPages);
    } else if (pageNumbers[pageNumbers.length - 1] === this.totalPages - 1) {
      pageNumbers.push(this.totalPages);
    }
  
    return pageNumbers;
  }
    
}
