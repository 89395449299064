import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VersionService } from '../../services/version.service';
import { faCirclePlay } from '@fortawesome/pro-duotone-svg-icons';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environment/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
})
export class HeaderComponent {

  image = environment.logo;
  hasLogin = environment.hasLogin;
  version;

  faLogout = faArrowRightFromBracket;

  constructor(
    private router: Router,
    public authService: AuthService,
    private _versionService: VersionService,
  ){}

  async ngOnInit() {
    if(this.hasLogin && !this.authService.isAuthenticated()) {
      return
    }
    
    this.version = await this._versionService.getVersion();
  }

  goRoute() {
    this.router.navigate(['/login']);

    if(this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    } else {
      window.location.reload();
    }
  }

}
