import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  async login(login: {username: string, password: string}): Promise<any>{
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/users/login`, login))
  }

  logout() {
    localStorage.clear();
    location.reload();
  }

  getUser() {
    const userData: any = localStorage.getItem('user');
    try {
      const user = JSON.parse(userData);

      return user;
    } catch (error) {
      this.logout();
    }
  }

  isAuthenticated() {
    const user = this.getUser();

    if (user) {
      return !this.jwtHelper.isTokenExpired(user.access_token);
    }
    return false;
  }

  getUserRole() {
    const user = this.getUser();

    if (user) {
      return user.role;
    }

    return null;
  }

  getUserToken() {
    const user = this.getUser();

    if (user) {
      return user.access_token;
    }

    return null;
  }
}
