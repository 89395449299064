import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { environment } from "../../../environment/environment";
import { ChatsService } from "../services/chats.service";

export const isLogged: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if(environment.hasLogin === false) {
    return true;
  }

  const authService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    const router = inject(Router);
    router.navigate(['/login']);
    return false;
  }
  return true;
};

export const isChatOpen: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if(environment.hasChatOpen) {
    return true;
  }

  const authService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    const router = inject(Router);
    router.navigate(['/login']);
    return false;
  }
  return true;
};

export const hasListChats: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const chatsList = inject(ChatsService);


  if(environment.multiChat === false) {
    const chats: any = await chatsList.getListChats(1);
    localStorage.setItem('selectedChat', chats.data[0].id_column);

    router.navigate(['chat-detail']);
    return false;
  }

  return true;
};

export const needSelectedChat: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);

  if(!localStorage.getItem('selectedChat')) {
    router.navigate(['list-chats']);
    return false;
  }

  return true;
};

