import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { PaginationComponent } from '../../core/components/pagination/pagination.component';
import { DropdownModule } from 'primeng/dropdown';
import { IndexingService } from '../../core/services/indexing.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environment/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    PaginationComponent,
    DropdownModule
  ]
})
export class IndexingComponent {
  loading = false;
  loadingResults = false;

  faSpinner = faSpinner;
  faBack = faChevronLeft;
  faUpload = faUpload;

  modalIndex = false;

  modifyFilesIndexed = false;

  tabs = [
    { title: 'INDEX.TAB_1', id: 'documents', isActive: true, hasTotal: true, loadData: this.loadDataDocuments.bind(this) },
    { title: 'INDEX.TAB_2', id: 'results', loadData: this.loadDataResults.bind(this) }
  ];

  documentDelete;

  documents;
  documentsPage = 1;

  results;
  resultsPage = 1;

  selectedFile;

  currentStatus;

  resultsForm = this.formBuilder.group({
    etl: ['', Validators.required],
    status: ['']
  });

  status;

  etls = [
  ];

  constructor(
    private route: ActivatedRoute,
    private _indexService: IndexingService,
    private formBuilder: FormBuilder,
    private _translateService: TranslateService
  ) { 
    this.modifyFilesIndexed = environment.hasModifyFilesIndexed;
  }

  async ngOnInit() {
    setTimeout(() => {
      this.status = [
        { name: '--', value: undefined },
        { name: this._translateService.instant('INDEX.TYPE_STATUS.INSERTED'), value: 'inserted'},
        { name: this._translateService.instant('INDEX.TYPE_STATUS.UPDATED'), value: 'updated' },
        { name: this._translateService.instant('INDEX.TYPE_STATUS.NOT_MODIFIED'), value: 'unmodified' },
        { name: this._translateService.instant('INDEX.TYPE_STATUS.DELETED'), value: 'deleted' },
        { name: this._translateService.instant('INDEX.TYPE_STATUS.ERROR'), value: 'error' }
      ];
    }, 100);

    this.loading = true;
    this.tabs[0].loadData();
  }

  isSelectedEtl() {
    const etl: any = this.resultsForm.get('etl').value;

    return etl && etl.value;
  }

  async indexEtl() {
    this.modalIndex = false;

    this._indexService.autoIndex().then(async () => {
      await this.loadDataDocuments(true);
    });

    this.currentStatus.status = 'ongoing';
  }

  async loadDataDocuments(reset = false) {
    this.loading = true;
    this.currentStatus = await this._indexService.getStatus();

    if (reset) {
      this.documentsPage = 1;
    }

    this.documents = await this._indexService.getSources(this.documentsPage);

    this.loading = false;
  }

  async loadDataResults(reset = false) {
    this.loading = true;
    this.currentStatus = await this._indexService.getStatus();

    const lastEtl: any = await this._indexService.getIndexes();
    this.etls = lastEtl.data.map((etl) => {
      return {
        name: `${etl.start_column} ${etl.type_column}`,
        value: etl.id_column
      }
    });

    this.etls.unshift({ name: '--', value: undefined });

    this.loading = false;
  }

  async searchResults(reset = false) {
    this.loadingResults = true;

    if (reset) {
      this.resultsPage = 1;
    }
    
    this.results = await this._indexService.getEtlResults(this.resultsForm.value, this.resultsPage);
    
    this.loadingResults = false;
  }

  changeTab(tab) {
    this.tabs.forEach(t => t.isActive = false);
    tab.isActive = true;

    tab.loadData(true);
  }

  async deleteDocument(document) {
    await this._indexService.deleteSource(document.id_column);
    this.documentDelete = undefined;
    await this.loadDataDocuments(true);
  }

  getIdCurrentTab() {
    return this.tabs.find(t => t.isActive).id;
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  async uploadFile() {
    this._indexService.addFile(this.selectedFile).then(async () => {
      this.selectedFile = undefined;
      await this.loadDataDocuments(true);
    });

    this.currentStatus.status = 'ongoing';
  }

  goBack() {
    window.history.back();
  }
}
