@if(totalPages > 0) {
    <div class="pagination is-centered is-rounded is-small mt-5">
        @if(currentPage !== 1) {
            <a class="pagination-link Pagination-previous" (click)="prevPage()">
                <fa-icon class="icon-search" [icon]="faPrevious"></fa-icon>
            </a>
        }

        @if(currentPage < (totalPages - 1)) {
            <a class="pagination-link pagination-next" (click)="nextPage()">
                <fa-icon class="icon-search" [icon]="faNext"></fa-icon>
            </a>
        }

        <ul class="pagination-list">
            <li>
                @for (page of totalPagesArray; let i = $index; track i) {
                    <a class="pagination-link" [ngClass]="{'is-bullets': (page === '...'), 'is-current': currentPage === (page)}" (click)="setPage(page)">
                        {{ page }}
                    </a>
                }
            </li>
        </ul>
    </div>
}
  