import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { CustomService } from '../../core/services/custom.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environment/environment';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    ToastModule,
    InputSwitchModule,
    TranslateModule
  ],
  providers: [MessageService]
})
export class CustomComponent {
  loading = false;
  isSaving = false;

  faSpinner = faSpinner;
  faBack = faChevronLeft;
  faUpload = faUpload;

  currentChat;
  selectedFile;

  chatForm = this.formBuilder.group({
    help_text: ['', Validators.required],
    title_chat: ['', Validators.required],
    sender_name: ['', Validators.required],
    color: ['', Validators.required],
    api: ['', Validators.required],
    image: ['', Validators.required],
    answer_files: [''],
    feedback_available: [''],
    attachment_available: ['']
  })

  constructor(
    private readonly formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _customService: CustomService,
    private router: Router,
    private messageService: MessageService,
    private _translateService: TranslateService
  ) { 
  }

  async ngOnInit() {
    this.loading = true;

    this.currentChat = await this._customService.getValue();
    this.currentChat.api = environment.apiUrl + '/' + localStorage.getItem('selectedChat');

    this.chatForm.patchValue(this.currentChat);

    this.loading = false;
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    this.selectedFile = file;

    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleFileRead.bind(this);
      reader.readAsDataURL(file);
    }
  }

  handleFileRead(event: any): void {
    this.chatForm.patchValue({
      image: event.target.result
    });
  }

  async saveChat() {
    this.isSaving = true;

    await this._customService.updateCustomization(this.chatForm.value);
    this.messageService.add({ severity: 'success', summary: this._translateService.instant('GENERIC.SAVED'), detail: this._translateService.instant('CUSTOMIZE.SAVED_TEXT') });

    this.isSaving = false;
  }

  goBack() {
    window.history.back();
  }

  checkCustomOption(option: string): boolean {
    return environment.customOptions.includes(option);
  }
}
