import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { PaginationComponent } from '../../core/components/pagination/pagination.component';
import { LogService } from '../../core/services/log.service';
import { CalendarModule } from 'primeng/calendar';
import dayjs from 'dayjs';
import { HistoricService } from '../../core/services/historic.service';
import { AccordionModule } from 'primeng/accordion';
import { CustomService } from '../../core/services/custom.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    PaginationComponent,
    CalendarModule,
    AccordionModule
  ]
})
export class HistoricComponent {
  loading = false;

  faSpinner = faSpinner;
  faBack = faChevronLeft;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;

  historic;
  metrics;
  currentPage = 1;

  chatConfig;

  historicForm = this.formBuilder.group({
    dateRange: [[dayjs().subtract(1, 'month').toDate(), new Date()]]
  });

  constructor(
    private route: ActivatedRoute,
    private _historicService: HistoricService,
    private formBuilder: FormBuilder,
    private _customService: CustomService,
    private _translateService: TranslateService
  ) { 
  }

  async ngOnInit() {
    this.loading = true;
    this.chatConfig = await this._customService.getValue();
    await this.loadData();
  }

  async loadData(reset = false) {
    if(reset) {
      this.currentPage = 1;
    }

    this.loading = true;

    await this.getSessions();
    await this.getMetrics();

    this.loading = false;
  }

  async getMetrics() {
    this.metrics = (await this._historicService.getMetrics(this.historicForm.value)).sessions;
    
    this.metrics.total_cost = this.metrics.total_cost ? (this.metrics.total_cost as Number).toFixed(4) : '-';
    this.metrics.average_cost = this.metrics.average_cost ? (this.metrics.average_cost as Number).toFixed(4) : '-';
    this.metrics.average_number_messages = this.metrics.average_number_messages ? (this.metrics.average_number_messages as Number).toFixed(1) : '-';
    const pad = (num: number) => (num < 10 ? "0" : "") + num;
    this.metrics.average_time = this.metrics.average_seconds ? (pad(Math.floor(this.metrics.average_seconds/3600)) + ':' + pad(Math.floor((this.metrics.average_seconds%3600)/60)) + ':' + pad(Math.floor((this.metrics.average_seconds%(60*3600))))) : '-';
  }

  async getSessions() {
    this.historic = await this._historicService.getHistoric(this.currentPage, this.historicForm.value);

    this.historic.data.sessions.forEach((session: any) => {
      session.session_id_reduced = session.id.split('_')[2];
      session.user_id = session.messages.find((message: any) => message.user_id_column)?.user_id_column;
      session.cost = session.messages.reduce((sum: number, currentMessage: any) => {
        if(currentMessage.cost_column) {
          sum += currentMessage.cost_column;
        }
        return sum;
      },0)

      session.messages.forEach((message: any) => {
        message.text_column = message.text_column.replace(/\n/g, "<br />");
        message.text_column = message.text_column.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
        message.text_column = this.findLinks(message.text_column);
      });
    });
  }

  findLinks(message: string): string {
    const regex = /\[([^\]]+)\]\(([^\)]+)\)/g;

    if(regex.test(message)) {
      return message.replace(regex, '<a href="$2" target="_blank">$1</a>');
    }

    return message;
  }

  getTitleSession(session: any): string {
    console.log(session);
    return `${this._translateService.instant('HISTORIC.DATE')}: ${session.start}, ${this._translateService.instant('HISTORIC.USER')}: ${session.user_id}, ${this._translateService.instant('HISTORIC.SESSION')}: ${session.session_id_reduced}, ${this._translateService.instant('HISTORIC.COST_SESSION')}: ${session.cost.toFixed(3)} $`
  }

  goBack() {
    window.history.back();
  }
}
