import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { StatusService } from '../../core/services/status.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule
  ]
})
export class StatusComponent {
  loading = false;

  faSpinner = faSpinner;
  faBack = faChevronLeft;
  faCheck = faCheck;
  faTimes = faTimes;

  status

  constructor(
    private route: ActivatedRoute,
    private _statusService: StatusService
  ) { 
  }

  async ngOnInit() {
    this.loading = true;
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.status = await this._statusService.getStatus();
    this.loading = false
  }

  goBack() {
    window.history.back();
  }
}
