import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class IndexingService {

  constructor(
    private http: HttpClient
  ) { }

  async initialize() {
    const body = {
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/init`, body));
  }

  async getSources(page: number): Promise<any> {
    const body = {
      page,
      per_page: 10
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/sources`, body));
  }

  async getEtlResults(filters: any, page: number){
    const body = {
      etl_id: filters.etl.value,
      ...(filters.status.value ? {status: filters.status.value} : {}),
      page,
      per_page: 10
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/etl_results`, body));
  }

  async deleteSource(source_id: string) {
    const body = {
      source_id
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/etl/remove_source`, body));
  }

  async getIndexes() {
    const body = {
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/db/etls`, body));
  }

  async getStatus() {
    const body = {
    }

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/etl/status`, body));
  }

  async autoIndex() {
    const body = {
    }

    const _ = await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/etl/auto_index`, body))
  }

  async addFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/${localStorage.getItem('selectedChat')}/etl/add_local_source`, formData));
  }
}
