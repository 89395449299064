export const environment = {
    production: true,
    apiUrl: 'https://chatbot.esgrima.es/api',
    logo: "./assets/logos/logo_rfee.png",
    multiChat: false,
    hasLogin: true,
    hasModifyFilesIndexed: false,
    hasChatOpen: false,
    customOptions: ['feedback_available'],
    tokenPublicUser: 'cHVibGljOlRLN2FLOFVxQkZGTHJl'
}