import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { PaginationComponent } from '../../core/components/pagination/pagination.component';
import { LogService } from '../../core/services/log.service';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import dayjs from 'dayjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    PaginationComponent,
    CalendarModule,
    InputTextModule,
    DropdownModule
  ]
})
export class LogComponent {
  loading = true;

  faSpinner = faSpinner;
  faBack = faChevronLeft;

  log;
  currentPage = 1;

  logForm = this.formBuilder.group({
    area_id: [''],
    user_id: [''],
    type_id: [''],
    dateRange: [[dayjs().subtract(1, 'month').toDate(), new Date()]]
  });

  areas;

  types;

  constructor(
    private route: ActivatedRoute,
    private _logService: LogService,
    private formBuilder: FormBuilder,
    private _translateService: TranslateService
  ) { 
  }

  async ngOnInit() {

    const areas = (await this._logService.getLogAreas())['data'];
    const types = (await this._logService.getLogTypes())['data'];

    this.areas = areas.map(area => ({ name: area.description_column, value: area.id_column }));
    this.areas.unshift({ name: '--', value: undefined });
    this.types = types.map(type => ({ name: type.description_column, value: type.id_column }));
    this.types.unshift({ name: '--', value: undefined });

    await this.loadData();
  }

  async loadData(reset = false) {
    if(reset) {
      this.currentPage = 1;
    }

    this.loading = true;
    this.log = await this._logService.getLog(this.currentPage, this.logForm.value);
    this.loading = false;
  }

  goBack() {
    window.history.back();
  }
}
