import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faBooks, faCabinetFiling, faCircleExclamation, faCirclePlay, faFileLines } from '@fortawesome/pro-duotone-svg-icons';
import { CustomService } from '../../core/services/custom.service';
import { IndexingService } from '../../core/services/indexing.service';
import { environment } from '../../../environment/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule,
    TranslateModule
  ]
})
export class ChatDetailsComponent {
  loading = false;
  firstModalIsVisible = false;
  secondModalIsVisible = false;
  hasMultiChat= environment.multiChat

  faSpinner = faSpinner;
  faBack = faChevronLeft;
  faBooksDuotone = faBooks;
  faFileLinesDuotone = faFileLines;
  faCabinetFilingDuotone = faCabinetFiling;
  faCircleExclamationDuotone = faCircleExclamation;
  faEdit = faEdit;
  faCirclePlayDuotone = faCirclePlay;

  boxes = [
    {
      title: 'CHAT_DETAIL.BOXES.HISTORIC',
      icon: this.faFileLinesDuotone,
      route: 'historic'
    },
    {
      title: 'CHAT_DETAIL.BOXES.INDEX',
      icon: this.faCabinetFilingDuotone,
      route: 'index'
    },
    {
      title: 'CHAT_DETAIL.BOXES.LOG',
      icon: this.faBooksDuotone,
      route: 'log'
    },
    {
      title: 'CHAT_DETAIL.BOXES.STATUS',
      icon: this.faCircleExclamationDuotone,
      route: 'status'
    },
    {
      title: 'CHAT_DETAIL.BOXES.CHATBOT',
      isLarge: true,
      iconIsSVG: true,
      route: 'demo-chat'
    }
  ];

  currentChat;

  constructor(
    private route: ActivatedRoute,
    private _customService: CustomService,
    private _indexingServices: IndexingService
  ) { 
  }

  async ngOnInit() {
    this.loading = true;
    this.currentChat = await this._customService.getValue();
    this.loading = false;
  }

  async init() {
    await this._indexingServices.initialize();
  }

  goBack() {
    if(this.hasMultiChat) {
      window.history.back();
    }
  }
}
