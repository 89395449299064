<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
        {{'INDEX.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column is-12 mb-3">
        <div class="tabs is-fullwidth">
          <ul>
            @for(tab of tabs; track tab;){
              <li (click)="changeTab(tab)" [ngClass]="{'is-active' : tab.isActive}"><a>{{tab.title | translate}} @if(tab.hasTotal && documents) {
                ({{documents.metadata.total_items}})
              }</a></li>
            }
          </ul>
        </div>
      </div>

      @if(currentStatus.status !== 'ongoing') {
        @switch (getIdCurrentTab()) {
          @case ('documents') {
            <div class="column is-12 is-flex index-status">
              <button class="button is-primary mr-3" (click)="modalIndex = true">
                {{'INDEX.INDEX_DOCUMENTS' | translate}}
              </button>

              <span>{{'INDEX.LAST_INDEX' | translate}} {{currentStatus.end}}</span>
            </div>

            @if(modifyFilesIndexed) {
              <div class="column is-10">
                <div class="mb-2">
                  <label class="label">{{'INDEX.INPUT_FILE' | translate}}</label>
                </div>
                <div class="file has-name is-fullwidth">
                  <label class="file-label">
                    <input class="file-input" type="file" accept=".docx, .pdf, .html" (change)="onFileChange($event)" />
                    <span class="file-cta">
                      <span class="file-icon">
                        <fa-icon [icon]="faUpload"></fa-icon>
                      </span>
                      <span class="file-label">{{'INDEX.SELECT_FILE' | translate}}</span>
                    </span>
                    <span class="file-name"> {{!selectedFile ? ('INDEX.SELECT_FILE_EMPTY' | translate) : selectedFile.name}} </span>
                  </label>
                </div>
              </div>
              <div class="column is-2 upload-button">
                <button [disabled]="!selectedFile" class="button is-primary" (click)="uploadFile()">
                  {{'INDEX.UPLOAD' | translate}}
                </button>
              </div>
            }

            <div class="column is-12">
              <table class="table is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th>
                      {{'INDEX.NAME' | translate}}
                    </th>
                    <th>
                      {{'INDEX.UPDATE_DATE' | translate}}
                    </th>
                    @if(modifyFilesIndexed) {
                      <th>
                        {{'INDEX.ACTIONS' | translate}}
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>
                    @for (item of documents.data; let i = $index; track i) {
                      <tr>
                        <td>
                          {{item.title_column}}
                        </td>
                        <td>
                          {{item.updated_at_column || '-'}}
                        </td>
                        @if(modifyFilesIndexed) {
                          <td>
                            <button class="button is-danger" (click)="documentDelete = item">{{'INDEX.DELETE' | translate}}</button>
                          </td>
                        }
                      </tr>
                    } @empty {
                      <tr>
                        <td colspan="3" class="has-text-centered">{{'GENERIC.NO_DATA' | translate}}</td>
                      </tr>
                    }
                </tbody>
              </table>
            </div>
            <div class="column is-12">
              <app-pagination
                [currentPage]="documentsPage"
                [totalPages]="documents.metadata.total_pages"
                (pageChange)="documentsPage = $event; loadDataDocuments()"></app-pagination>
            </div>
          }
          @case ('results') {
            <ng-container [formGroup]="resultsForm">
              <div class="column is-4">
                <div class="mb-2">
                  <label class="label">{{'INDEX.SELECT_INDEX' | translate}}</label>
                </div>
                <p-dropdown 
                  formControlName="etl"
                  [options]="etls"
                  optionLabel="name" 
                  />
              </div>
              <div class="column is-3">
                <div class="mb-2">
                  <label class="label">{{'INDEX.STATUS' | translate}}</label>
                </div>
                <p-dropdown 
                  formControlName="status"
                  [options]="status"
                  optionLabel="name" 
                  />
              </div>
              <div class="column is-5 upload-button">
                <button [disabled]="!isSelectedEtl()" class="button is-primary" (click)="searchResults(true)">
                  {{'INDEX.SEARCH' | translate}}
                </button>
              </div>

              @if(loadingResults) {
                <div class="column is-12">
                  <div class="spinner-container is-flex is-justify-content-center">
                    <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
                  </div>
                </div>
              } @else {
                @if(results) {
                  <div class="column is-12">
                    <table class="table is-fullwidth is-striped">
                      <thead>
                        <tr>
                          <th>
                            {{'INDEX.DOCUMENT' | translate}}
                          </th>
                          <th>
                            {{'INDEX.STATUS_DOCUMENT' | translate}}
                          </th>
                          <th>
                           {{'INDEX.COST' | translate}}
                          </th>
                          <th>
                            {{'INDEX.NOTE' | translate}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          @for (item of results.data; let i = $index; track i) {
                            <tr>
                              <td>
                                {{item.source_title_column}}
                              </td>
                              <td>
                                {{item.status_column}}
                              </td>
                              <td>
                                {{item.cost_column.toFixed(3)}} $
                              </td>
                              
                              <td>
                                {{item.note_column || '-'}}
                              </td>
                            </tr>
                          } @empty {
                            <tr>
                              <td colspan="4" class="has-text-centered">{{'GENERIC.NO_DATA' | translate}}</td>
                            </tr>
                          }
                      </tbody>
                    </table>
                  </div>
                  <div class="column is-12">
                    <app-pagination
                      [currentPage]="resultsPage"
                      [totalPages]="results.metadata.total_pages"
                      (pageChange)="resultsPage = $event; searchResults()"></app-pagination>
                  </div>
                }
              }
            </ng-container>
          }
        }
      } @else {
        <div class="column is-12 is-flex is-justify-content-center">
          <span>{{'INDEX.INDEXING' | translate}}</span>
        </div>
      }
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': documentDelete !== undefined}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{'INDEX.TITLE_MODAL_DELETE' | translate}}</p>
      <button class="delete" aria-label="close" (click)="documentDelete = undefined"></button>
    </header>
    <section class="modal-card-body">
      <p [innerHTML]="'INDEX.TEXT_MODAL_DELETE' | translate: {documentDelete: documentDelete?.title_column}">
      </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-danger" (click)="deleteDocument(documentDelete)">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="documentDelete = undefined">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>

<div class="modal" [ngClass]="{'is-active': modalIndex}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-primary">{{'INDEX.TITLE_MODAL_INDEX' | translate}}</p>
      <button class="delete" aria-label="close" (click)="modalIndex = false"></button>
    </header>
    <section class="modal-card-body">
      <p>
        {{'INDEX.TEXT_MODAL_INDEX' | translate}}
      </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-primary" (click)="indexEtl()">{{'GENERIC.ACCEPT' | translate}}</button>
        <button class="button" (click)="modalIndex = false">{{'GENERIC.CANCEL' | translate}}</button>
      </div>
    </footer>
  </div>
</div>