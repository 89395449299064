<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
          {{'CUSTOMIZE.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline" [formGroup]="chatForm">
    @if(!loading){
      <div class="column is-6 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_TITLE' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" formControlName="title_chat" type="text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_NAME' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" formControlName="sender_name" type="text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_MESSAGE' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" formControlName="help_text" type="text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_API' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" [readonly]="true" formControlName="api" type="text" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_LOGO' | translate}}</label>
        </div>
        <div class="file has-name is-fullwidth">
          <label class="file-label">
            <input class="file-input" formControlName="image" type="file" accept="image/*" (change)="onFileSelected($event)"/>
            <span class="file-cta">
              <span class="file-icon">
                <fa-icon [icon]="faUpload"></fa-icon>
              </span>
              <span class="file-label">{{'CUSTOMIZE.SELECT_FILE' | translate}}</span>
            </span>
            <span class="file-name"> {{!selectedFile ? ('CUSTOMIZE.SELECT_FILE_EMPTY' | translate) : selectedFile.name}} </span>
          </label>
        </div>
      </div>
      <div class="column is-1 mb-3 container-image">
        <img
            *ngIf="chatForm.get('image').value"
            [src]="chatForm.get('image').value"
            class="img-fluid"
        />
      </div>
      <div class="column is-2 mb-3">
        <div class="mb-2">
          <label class="label">{{'CUSTOMIZE.INPUT_COLOR' | translate}}</label>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field">
              <p class="control">
                <input class="input" formControlName="color" type="color" />
              </p>
            </div>
          </div>
        </div>
      </div>
      @if(checkCustomOption('feedback_available')) {
        <div class="column is-2 mb-3 switch-input">
          <div class="mr-4">
            <label class="label">{{'CUSTOMIZE.INPUT_FEEDBACK' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="feedback_available"/>
              </div>
            </div>
          </div>
        </div>
      }
      @if(checkCustomOption('answer_files')) {
        <div class="column is-3 mb-3 switch-input">
          <div class="mr-4">
            <label class="label">{{'CUSTOMIZE.INPUT_SELECT_FILE' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="answer_files"/>
              </div>
            </div>
          </div>
        </div>
      }
      @if(checkCustomOption('attachment_available')) {
        <div class="column is-3 mb-3 switch-input">
          <div class="mr-4 mb-2">
            <label class="label">{{'CUSTOMIZE.INPUT_ATTACH_FILE' | translate}}</label>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <p-inputSwitch formControlName="attachment_available"/>
              </div>
            </div>
          </div>
        </div>
      }
      <div class="column is-12 is-flex is-justify-content-end">
        <button class="button is-primary" [disabled]="chatForm.invalid" (click)="saveChat()">
          @if(isSaving) {
            <fa-icon class="mr-3" [icon]="faSpinner" [spin]="true"></fa-icon>
          }

          {{'CUSTOMIZE.BUTTON' | translate}}
        </button>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>

<p-toast />