<div class="container">
  <div class="columns">
    <div class="column">
      <h4 class="subtitle is-4 is-clickable" (click)="goBack()">
        <fa-icon class="mr-1" [icon]="faBack"></fa-icon>
        {{'LOG.TITLE' | translate}}
      </h4>
    </div>
  </div>
  <div class="columns is-multiline">
    @if(!loading){
      <div class="column is-12 mb-4">
        <div class="columns" [formGroup]="logForm">
          <div class="column is-3">
            <div class="mb-2">
              <label class="label">{{'LOG.FILTER_DATE' | translate}}</label>
            </div>
            <p-calendar
              dateFormat="dd/mm/yy"
              formControlName="dateRange"
              selectionMode="range"/>
          </div>
          <div class="column is-3">
            <div class="mb-2">
              <label class="label">{{'LOG.FILTER_USER' | translate}}</label>
            </div>
            <input type="text" formControlName="user_id" pInputText />
          </div>
          <div class="column is-2">
            <div class="mb-2">
              <label class="label">{{'LOG.FILTER_AREA' | translate}}</label>
            </div>
            @if(areas) {
              <p-dropdown 
                formControlName="area_id"
                [options]="areas"
                optionLabel="name" 
                />
            }
          </div>
          <div class="column is-2">
            <div class="mb-2">
              <label class="label">{{'LOG.FILTER_TYPE' | translate}}</label>
            </div>
            @if(types) {
              <p-dropdown 
                formControlName="type_id"
                [options]="types"
                optionLabel="name" 
                />
            }
          </div>
          <div class="column is-2 is-flex is-justify-content-end search-button-container">
            <button class="button is-primary" (click)="loadData(true)">
              {{'LOG.SEARCH' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>
                {{'LOG.AREA' | translate}}
              </th>
              <th>
                {{'LOG.DATE' | translate}}
              </th>
              <th>
                {{'LOG.DESCRIPTION' | translate}}
              </th>
              <th>
                {{'LOG.TYPE' | translate}}
              </th>
              <th>
                {{'LOG.USER' | translate}}
              </th>
            </tr>
          </thead>
          <tbody>
              @for (item of log.data; let i = $index; track i) {
                <tr>
                  <td>
                    {{item.area_id_column}}
                  </td>
                  <td>
                    {{item.date_column}}
                  </td>
                  <td class="limit-text">
                    {{item.description_column}}
                  </td>
                  <td>
                    {{item.type_id_column}}
                  </td>
                  <td>
                    {{item.user_id_column}}
                  </td>
                </tr>
              } @empty {
                <tr>
                  <td colspan="5" class="has-text-centered">{{'GENERIC.NO_DATA' | translate}}</td>
                </tr>
              }
          </tbody>
        </table>
      </div>
      <div class="column is-12">
        <app-pagination
          [currentPage]="currentPage"
          [totalPages]="log.metadata.total_pages"
          (pageChange)="currentPage = $event; loadData()"></app-pagination>
      </div>
    } @else {
      <div class="column">
        <div class="spinner-container is-flex is-justify-content-center">
          <fa-icon class="spinner-icon" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
      </div>
    }
  </div>
</div>