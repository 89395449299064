import { Component } from '@angular/core';
import {Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [RouterOutlet, CommonModule, HeaderComponent],
})
export class MainLayoutComponent {

  constructor(public router: Router) {
  }
}
