<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" (click)="goRoute()">
      <img src="{{image}}" alt="logo" width="125" height="75">
    </a>
  </div>
  @if(hasLogin) {
    <div class="navbar-menu">
      <div class="navbar-end">
        @if(version) {
          <div class="navbar-item">
            {{'GENERIC.HEADER.VERSION' | translate}} {{ version }}
          </div>
        }
        <div class="navbar-item user-name gray-hover" (click)="authService.logout()">
          @if(authService.getUser()) {
            <span>{{ authService.getUser().username }}</span>
            <fa-icon class="icon ml-1" [icon]="faLogout"></fa-icon>
          }
        </div>
      </div>
    </div>
  }
</nav>