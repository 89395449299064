import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatsService {

  constructor(
    private http: HttpClient
  ) { }

  async getListChats(page: number) {
    const body = {
      page,
      "per_page": 10,
    };

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/db/chats`, body))
  }

  async deleteChat(chatId: string) {
    const body = {
      "chat_id": chatId
    };

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/chats/remove`, body))
  }

  async newChat(name: string) {
    const body = {
      name
    };

    return await lastValueFrom(this.http.post(`${environment.apiUrl}/chats/new`, body))
  }
}
