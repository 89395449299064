<div class="hero is-fullheight is-flex is-justify-content-center is-align-items-center">
  <div class="columns container-data pb-6 px-5 py-4">
    <div class="column is-flex is-align-items-center">
      <div class="logo-wrapper">
        <img src="{{image}}" class="image logo">
    </div>
    </div>
    <div class="column">
      <div class="form-container p-6">
        <h4 class="wellcome subtitle is-bold is-4 mb-3">{{'LOGIN.TITLE' | translate}}</h4>
        <h5 class="subtitle is-5 mb-5">{{'LOGIN.LOGIN' | translate}}</h5>

        <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
          <div class="field">
            <label class="label">{{'LOGIN.USER' | translate}}</label>
            <div class="control">
              <input class="input is-medium" type="text" formControlName="username">
            </div>
          </div>
          <div class="field">
            <label class="label">{{'LOGIN.PASSWORD' | translate}}</label>
            <div class="control">
              <input class="input is-medium" type="password" formControlName="password">
            </div>
          </div>
          <button type="submit" class="button is-medium is-primary is-fullwidth mt-5" [disabled]="!loginForm.valid">
            @if (!isLoading) {
            <span>{{'LOGIN.BUTTON' | translate}}</span>
            } @else {
            <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
            }
          </button>
          @if(hasErrorLogin) {
            @switch (errorCode) {
            @case (401) {
              <p class="mt-4 has-text-danger">{{'LOGIN.ERROR' | translate}}</p>
            }
            @default {
              <p class="mt-4 has-text-danger">{{'GENERIC.ERROR' | translate}}</p>
            }
            }
          }
        </form>
      </div>
    </div>
  </div>
</div>